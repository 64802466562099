/* src/App.css */
.header {
  background-color:  #fdf2e9 ; /* Change to your desired color */
  color:   #1b2631 ;
  height: auto;
}

.footer {
  background-color: #ffffff; /* Change to your desired color */
  text-align: center;
  color: #000000;
}

.content {
  background-color: #ffffff; /* Change to your desired color */
  padding: 24px;
}


.intro {
    padding: 50px;
    text-align: center;
}

